$(function(){

  var $slider = $('.slide-featured');
  $slider.slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000
  });
  var $sliderSimple = $('.slide-itens');
  $sliderSimple.slick({
    dots: false,
    arrows: false,
    autoplay: true,
		fade: true,
    autoplaySpeed: 5000
  });
});
