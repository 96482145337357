"use strict";
'use strict';

$(function () {
	"use strict";

	var $buttonMenu = $('.btn-menu');
	var $menuItem = $('.menu');
	var $body = $('body');

	if ($buttonMenu.length) {

		$body.on('click', function () {
			$menuItem.removeClass('active');
		});

		$buttonMenu.on('click', function (e) {
			e.preventDefault();
			$body.toggleClass('menu-open');
		});

		$menuItem.on('click', function (e) {
			var haveSubMenu = false;
			// Verify if the element clicked has submenu
			if (e.target !== this) {
				e.stopPropagation();
				haveSubMenu = $(this).find('.sub-menu').length !== 0;
				console.log(haveSubMenu);
			}
			// Prevent location with in mobile
			if (window.innerWidth < 800 && haveSubMenu) {
				e.preventDefault();
			}
			$(this).toggleClass('active');
		});
	}
});
'use strict';

$(function () {
	"use strict";

	var $containerSearch = $('.search--js'),
	    $btnSearch = $('.button-search--js');

	$btnSearch.on('click', function () {
		$containerSearch.slideToggle();
		$containerSearch.find('input').focus();

		$(this).find('.fa').toggleClass('fa-search fa-times');
	});
});
"use strict";

$(function () {
	"use strict";

	var shareButtons = document.querySelectorAll(".share > .item");

	if (shareButtons) {
		[].forEach.call(shareButtons, function (button) {
			button.addEventListener("click", function (event) {
				var width = 650,
				    height = 450;

				event.preventDefault();

				window.open(this.href, 'Share Dialog', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=' + width + ',height=' + height + ',top=' + (screen.height / 2 - height / 2) + ',left=' + (screen.width / 2 - width / 2));
			});
		});
	}
});
'use strict';

$(function () {

  var $slider = $('.slide-featured');
  $slider.slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000
  });
  var $sliderSimple = $('.slide-itens');
  $sliderSimple.slick({
    dots: false,
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000
  });
});